import Script from "next/script";
import * as Sentry from "@sentry/nextjs";
import { QueryClientProvider, QueryClient } from "react-query";
import { H } from "highlight.run";
import { Analytics } from "@vercel/analytics/react";

import AuthCheck from "components/auth-check";
import { AuthProvider } from "context/auth-context";
import ToastProvider from "context/toast-context";
import PromoBar from "components/promo-bar";
import "styles/tailwind.css";
import "styles/globals.css";
import "@highlight-run/react/dist/highlight.css";
import { useEffect } from "react";
import segmentUtils from "utils/segmentUtils";

const HIGHLIGHT_ID = process.env.NEXT_PUBLIC_HIGHLIGHT_ID;
const queryClient = new QueryClient();
// const KUSTOMER_ID = process.env.NEXT_PUBLIC_KUSTOMER_API_KEY;
// const KUSTOMER_BRAND_ID = process.env.NEXT_PUBLIC_KUSTOMER_BRAND_ID;
const GOOGLE_PLACES_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_KEY;
const GOOGLE_OPTIMIZE_CONTAINER_ID = process.env.NEXT_PUBLIC_OPTIMIZE_CONTAINER_ID;

H.init(HIGHLIGHT_ID);

H.getSessionURL().then((sessionUrl) => {
  Sentry.setContext("highlight", {
    url: sessionUrl,
  });
});

function MyApp({ Component, pageProps }) {
  const { data: pageData = {}, slug } = pageProps;
  useEffect(() => {
    const { page: { optimizeExperimentId, optimizeExperimentVariant } = {} } = pageData;
    if (optimizeExperimentId && optimizeExperimentVariant) {
      segmentUtils.sendExperimentEvent(optimizeExperimentId, optimizeExperimentVariant);
    }
  }, [slug]);

  const pageTitle = pageProps.dynamicContent ? pageProps.dynamicContent.page?.title : pageData.page?.title;
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ToastProvider>
            <AuthCheck pageTitle={pageTitle}>
              <PromoBar data={pageProps.data?.site?.promoBar} />
              <Component {...pageProps} />
            </AuthCheck>
          </ToastProvider>
        </AuthProvider>
      </QueryClientProvider>
      {/*   <Script
        strategy="afterInteractive"
        src="https://cdn.kustomerapp.com/chat-web/widget.js"
        data-kustomer-api-key={KUSTOMER_ID}
        onLoad={() => {
          if (typeof window !== "undefined") {
            window.Kustomer?.start?.({
              brandId: KUSTOMER_BRAND_ID,
            });
          }
        }}
      /> */}

      <Analytics />
      <Script
        strategy="afterInteractive"
        src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_KEY}&libraries=places`}
      />
      <Script src={`https://www.googleoptimize.com/optimize.js?id=${GOOGLE_OPTIMIZE_CONTAINER_ID}`} />
    </>
  );
}

export default MyApp;
