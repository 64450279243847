const segmentUtils = {
  sendQuizAnswerSubmitEvent: ({ quizQuestion, currentAnswers, user, options }) => {
    if (!window.analytics?.track) {
      return;
    }
    const isSpaceQuestion = quizQuestion._type === "quizQuestionSpace";
    const isImageQuestion = quizQuestion._type === "quizQuestionImage";
    const isAddressQuestion = quizQuestion._type === "quizQuestionAddress";
    const isAccountQuestion = quizQuestion._type === "quizQuestionAccount";
    const finalProps = {
      type: quizQuestion._type,
      internal_title: quizQuestion.internalTitle,
      question_title: quizQuestion.questionTitle.questionTitle,
      question_options: isAddressQuestion
        ? []
        : quizQuestion.questionAnswers?.map((answer) => {
            if (isSpaceQuestion) {
              return answer?.space;
            }
            if (isImageQuestion) {
              return {
                id: answer._id,
                title: answer.title,
              };
            }
            return answer?.primaryText;
          }),
    };

    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }
    if (quizQuestion._type === "quizQuestionStandard") {
      finalProps.answer = currentAnswers?.primaryText;
    }
    if (isSpaceQuestion) {
      finalProps.answer = Object.keys(currentAnswers || {}).map((spaceId) => {
        const answerSpace = quizQuestion.questionAnswers.find((answerOpt) => +(answerOpt.space?.id || 0) === +spaceId);
        return { ...answerSpace?.space, quantity: currentAnswers[spaceId] };
      });
    }
    if (isImageQuestion) {
      finalProps.answer = currentAnswers?.map((imageId) => {
        const answerImage = quizQuestion.questionAnswers.find((answerOpt) => +(answerOpt?._id || 0) === +imageId);
        return { id: answerImage?._id, title: answerImage?.title };
      });
    }
    if (isAddressQuestion || isAccountQuestion) {
      finalProps.answer = currentAnswers;
    }
    window?.analytics?.track?.("Quiz Answer Submitted", { ...finalProps }, options);
  },
  sendQuizStartedEvent: (quizGroupdId, quizType, user, options) => {
    if (!window.analytics?.track) {
      return;
    }
    const finalProps = {
      quiz_group: quizGroupdId,
      quiz_type: quizType,
    };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }
    window.analytics.track("Quiz Started", finalProps, options);
  },
  sendQuizEndedEvent: (formTitle, formPath, payload, user, options) => {
    if (!window.analytics?.track) {
      return;
    }

    const finalProps = {
      form_title: formTitle,
      form_path: formPath,
      question_answers: payload,
    };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }
    window.analytics.track("Quiz Ended", finalProps, options);
  },
  sendQuizStyleImagesSubmitEvent: (quizQuestion, currentAnswers = [], user, options) => {
    if (!window.analytics?.track) {
      return;
    }
    const selectedImages = currentAnswers.find((item) => item._key === quizQuestion._key)?.imagesAnswer;
    const finalProps = {
      images: selectedImages?.map((imageId) => {
        const answerImage = quizQuestion.questionAnswers.find((answerOpt) => answerOpt?._id === imageId);
        return { id: answerImage?._id, title: answerImage?.title };
      }),
    };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }
    window.analytics.track(
      "Quiz Style Images Submitted",
      {
        ...finalProps,
      },
      options
    );
  },
  sendUserSignedUpEvent: (user) => {
    if (!window.analytics?.track) {
      return;
    }

    const finalProps = {
      email: user.email,
      first_name: user.firstName,
      phone: user.phone,
      last_name: user.lastName,
      address: {
        street: user.addressLine1,
        city: user.city,
        state: user.state,
        postalCode: user.zipCode,
      },
    };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }
    window.analytics.track("User Signed Up", finalProps);
  },
  sendSpaceEvents: ({ eventProps, eventTitle, user, options }) => {
    if (!window.analytics?.track) {
      return;
    }

    const finalProps = { ...eventProps };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }

    window.analytics.track(eventTitle, finalProps, options);
  },
  sendExperimentEvent: (experimentId, variantId) => {
    if (!window.analytics?.track) {
      return;
    }
    if (experimentId && variantId) {
      const finalProps = { experiment_id: experimentId, variant_id: variantId };
      window.analytics.track("optimize.activate", finalProps);
    }
  },
  sendScheduledConsultationEvent: (eventProps, user, options) => {
    if (!window.analytics?.track || !eventProps) {
      return;
    }
    const finalProps = {
      event_name: eventProps.name,
      event_time: eventProps.start_time,
      event_location: eventProps.location,
    };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }
    window.analytics.track("Scheduled Consultation", finalProps, options);
  },
  sendClickedGalleryPhotoEvent: (eventProps, user, options) => {
    if (!window.analytics?.track || !eventProps) {
      return;
    }

    const finalProps = { file_url: eventProps.url, design_styles: eventProps.styles };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }
    window.analytics.track("Clicked Gallery Photo", finalProps, options);
  },
  sendQuizCompletedEvent: (payload, user, options) => {
    if (!window.analytics?.track) {
      return;
    }
    const finalProps = { question_answers: payload };
    if (user) {
      finalProps.userTraits = {
        email: user.email,
        name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
        phone: user.phone,
        address: {
          street: user.places?.addressLine1,
          city: user.places?.city,
          state: user.places?.state,
          postalCode: user.places?.zipCode,
        },
      };
    }

    window.analytics.track("Quiz Completed", finalProps, options);
  },
};

export default segmentUtils;
