import Cookies from "universal-cookie";

const cookieClient = new Cookies();

export const setCookie = (key, value, options = {}) => {
  if (!key) {
    return;
  }

  cookieClient.set(key, value, options);
};

export const getCookie = (key) => {
  return cookieClient.get(key);
};

export const deleteCookie = (key, options) => {
  return cookieClient.remove(key, options);
};
