export const PRODUCTION_URL = process.env.NEXT_PUBLIC_PRODUCTION_URL;

export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BA_CLIENT_COOKIE = "ba_client_session";

export const CONTENT_TYPES = {
  ClientSubmitted: "Client Submitted",
};
