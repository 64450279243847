import Img from "next/image";
import { pickBy, identity } from "lodash";
import PropTypes from "prop-types";
import useNextSanityImage from "../lib/useNextSanityImage";
import { sanityClient } from "lib/sanity.server";

const Picture = ({ picture, sizes, width, height, ...props }) => {
  const imgProps = {
    ...useNextSanityImage(sanityClient, picture.image),
    ...(sizes ? { sizes } : { width, height }),
    ...pickBy(props, identity),
  };

  return <Img {...imgProps} />;
};

const PictureWrapper = (props) => (props?.picture ? <Picture {...props} /> : null);

PictureWrapper.propTypes = {
  ref: PropTypes.any,
  onClick: PropTypes.func,
  layout: PropTypes.string,
  objectFit: PropTypes.string,
  className: PropTypes.string,
  picture: PropTypes.shape({
    image: PropTypes.shape({}),
  }),
};

export default PictureWrapper;
