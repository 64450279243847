import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { useRouter } from "next/router";

import { getCurrentUser } from "services/auth/api";
import { useAuthState } from "context/auth-context";
import { setClientHeader } from "lib/graphqlClient";
import { setCookie, deleteCookie } from "lib/universalCookie";
import { BA_CLIENT_COOKIE } from "utils/constants";

const useUser = () => {
  const { authState, updateAuthStatus } = useAuthState();
  const router = useRouter();

  const { refetch: getCurrentUserDetails, isLoading } = useQuery("currentUser", getCurrentUser, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: (data) => {
      const { getCurrentUserDetails } = data;
      if (isEmpty(getCurrentUserDetails?.[getCurrentUserDetails.role])) {
        updateAuthStatus({ isLoggedIn: false, currentUser: null });
        return;
      }
      if (getCurrentUserDetails.status === "unverified") {
        updateAuthStatus({ isUnverified: true });
      } else {
        updateAuthStatus({ isUnverified: false });
      }
      const userDetails = {
        uuid: getCurrentUserDetails.uuid,
        email: getCurrentUserDetails.email,
        ...getCurrentUserDetails[getCurrentUserDetails.role],
        status: getCurrentUserDetails.status,
        avatar: getCurrentUserDetails[getCurrentUserDetails.role].avatar?.[0],
      };
      updateAuthStatus({ currentUser: userDetails });
    },
    onError: () => {
      updateAuthStatus({ isLoggedIn: false, currentUser: null });
    },
  });

  const updateLoggedIn = (newToken) => {
    setCookie(BA_CLIENT_COOKIE, newToken, {
      path: "/",
      maxAge: 86400,
      domain: process.env.NEXT_PUBLIC_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
    updateAuthStatus({ isLoggedIn: true, currentUser: null });
    setClientHeader("Authorization", newToken);
  };

  const logout = () => {
    deleteCookie(BA_CLIENT_COOKIE, {
      path: "/",
      domain: process.env.NEXT_PUBLIC_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
    updateAuthStatus({ isLoggedIn: false, currentUser: null });
    setClientHeader("Authorization", null);
    router.push("/login");
  };

  useEffect(() => {
    if (authState.isLoggedIn && !authState.currentUser) {
      getCurrentUserDetails();
    }
  }, [authState]);

  return {
    user: authState.currentUser,
    ...authState,
    updateLoggedIn,
    logout,
    isLoading,
  };
};

export default useUser;
