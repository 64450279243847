import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import Picture from "components/picture";
import { getCookie, setCookie } from "lib/universalCookie";
import { UilTimes as CloseIcon } from "@iconscout/react-unicons";

const PromoBar = ({ data }) => {
  const router = useRouter();

  const isInDashboard = router.route === "/dashboard";

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setShowBanner(getCookie("hidePromoBar") !== "true");
  }, []);

  const handleShowBanner = () => {
    setShowBanner(false);
    setCookie("hidePromoBar", "true", {
      path: "/",
      maxAge: 86400,
      domain: process.env.NEXT_PUBLIC_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
  };

  if (showBanner && !isInDashboard && data?.active) {
    return (
      <div
        className={cx(
          "theme--softgradient relative z-20 flex items-center justify-between bg-[#fff] py-2.5 px-2 text-[#4F4946] lg:px-6",
          data.theme?.value ? `theme theme--${data.theme.value} theme--icon` : null
        )}
      >
        <div className="flex gap-x-3">
          {data.icon && <Picture picture={{ image: data.icon.asset }} width={16} height={16} />}
          <p className="whitespace-pre-wrap text-xs">
            {data.title}{" "}
            <Link href={data.link?.url} passHref>
              <span className="ml-3 cursor-pointer underline">{data.link?.title}</span>
            </Link>
          </p>
        </div>
        <div>
          <CloseIcon onClick={() => handleShowBanner()} size="20" />
        </div>
      </div>
    );
  }

  return null;
};

PromoBar.propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.shape({
      value: PropTypes.oneOf(["light", "soft", "dark", "new"]),
    }),
    icon: PropTypes.shape({
      asset: PropTypes.object,
    }),
    title: PropTypes.string,
    link: PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  }),
};

export default PromoBar;
