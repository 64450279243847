import { gql } from "graphql-request";

import axios from "lib/axios";
import graphQLClient, { setClientHeader } from "lib/graphqlClient";
import { BA_CLIENT_COOKIE } from "utils/constants";
import { getCookie, setCookie } from "lib/universalCookie";

const GET_CURRENT_USER = gql`
  query getCurrentUserDetails {
    getCurrentUserDetails {
      uuid
      email
      role
      status
      clients {
        id
        firstName
        lastName
        avatar
        places {
          addressLine1
          city
          state
          zipCode
        }
        phone
        stripeCustomerID
      }
      staff {
        id
        firstName
        lastName
        avatar
        phone
      }
    }
  }
`;

export const getCurrentUser = async () => {
  return await graphQLClient.request(GET_CURRENT_USER);
};

export const getClientCookie = () => {
  return getCookie(BA_CLIENT_COOKIE);
};

export const sendMagicLink = async ({ email, redirectUrl }) => {
  const { data } = await axios.post(`${process.env.NEXT_PUBLIC_AUTH_URL}/send-magic-link`, {
    email,
    redirectUrl,
  });

  return data;
};

export const checkUserStatus = async ({ email, phone }) => {
  const userResponse = await axios.post(`${process.env.NEXT_PUBLIC_AUTH_URL}/check-user`, {
    email,
    phone,
  });

  return userResponse.data;
};

export const newUserLogin = async ({ email, name, phone }) => {
  const newUserResponse = await axios.post(`${process.env.NEXT_PUBLIC_AUTH_URL}/new-user?application=client`, {
    email,
    name,
    phone,
  });
  let finalAuthHeader = "";
  if (newUserResponse.headers?.authorization || newUserResponse.headers?.["x-amzn-remapped-authorization"]) {
    finalAuthHeader =
      newUserResponse.headers?.["x-amzn-remapped-authorization"] || newUserResponse.headers?.authorization;
    setCookie(BA_CLIENT_COOKIE, finalAuthHeader.split(" ")[1], {
      path: "/",
      maxAge: 604800,
      domain: process.env.NEXT_PUBLIC_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
  }
  return { status: "redirect", email: newUserResponse.data.email, token: finalAuthHeader.split(" ")?.[1] };
};

export const verifyToken = async (clientCookie, isImpersonateCookie = false) => {
  const verifyTokenResponse = await axios.get(`${process.env.NEXT_PUBLIC_AUTH_URL}/verifyToken`, {
    headers: {
      Authorization: !!isImpersonateCookie ? isImpersonateCookie : clientCookie,
    },
  });

  if (![200, 204].includes(verifyTokenResponse.status) && isImpersonateCookie) {
    // Clear all cookies
    setCookie(BA_CLIENT_COOKIE, "");
    setCookie("ba_imp_token", "");
    setClientHeader("Authorization", "");
  }

  if (
    (verifyTokenResponse.headers?.authorization || verifyTokenResponse.headers?.["x-amzn-remapped-authorization"]) &&
    !isImpersonateCookie
  ) {
    const finalAuthHeader =
      verifyTokenResponse.headers?.["x-amzn-remapped-authorization"] || verifyTokenResponse.headers?.authorization;
    setCookie(BA_CLIENT_COOKIE, finalAuthHeader.split(" ")[1], {
      path: "/",
      maxAge: 259200, // cookie will be removed after 3 days - TEMPORARILY FOR DEVELOPMENT
      domain: process.env.NEXT_PUBLIC_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });

    return { email: verifyTokenResponse.data.email, token: finalAuthHeader.split(" ")[1] };
  }
};

export const verifyImpersonateTokenAndLogin = async () => {
  const impToken = getCookie("ba_imp_token");
  if (impToken) {
    const updatedVerifyTokenResp = await axios.get(`${process.env.NEXT_PUBLIC_AUTH_URL}/verifyToken`, {
      headers: {
        Authorization: impToken,
      },
    });

    if ([200, 204].includes(updatedVerifyTokenResp.status)) {
      // Reset client authorization to impersonate token
      setCookie("ba_client_session", impToken, {
        path: "/",
        maxAge: 3600,
        domain: process.env.NEXT_PUBLIC_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
      });

      setClientHeader("Authorization", impToken);
      return "Authenticated";
    }
  }
  return "Unauthorized";
};

export const sendUserOTP = async ({ phone }) => {
  const { data } = await axios.post(`${process.env.NEXT_PUBLIC_AUTH_URL}/send-otp`, {
    phone,
  });

  return data;
};

export const internalLogin = async ({ email, phone, code, methodId, provider }) => {
  const loginResponse = await axios.post(
    `${process.env.NEXT_PUBLIC_AUTH_URL}/login?application=client`,
    {
      email,
      phone,
      methodId,
      provider,
    },
    {
      headers: {
        Authorization: "Bearer " + code,
      },
    }
  );
  let finalAuthHeader = "";
  if (loginResponse.headers?.authorization || loginResponse.headers?.["x-amzn-remapped-authorization"]) {
    finalAuthHeader = loginResponse.headers?.["x-amzn-remapped-authorization"] || loginResponse.headers?.authorization;
    setCookie(BA_CLIENT_COOKIE, finalAuthHeader.split(" ")[1], {
      path: "/",
      maxAge: 86400,
      domain: process.env.NEXT_PUBLIC_APP_IS_LOCAL ? "localhost" : ".buildappeal.com",
    });
  }
  return {
    email: loginResponse.data.email,
    phone: loginResponse.data.phone,
    accessToken: finalAuthHeader.split(" ")?.[1] || null,
  };
};
