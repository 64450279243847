import { useMemo } from "react";
import imageUrlBuilder from "@sanity/image-url";

export const DEFAULT_BLUR_UP_IMAGE_WIDTH = 64;
export const DEFAULT_BLUR_UP_IMAGE_QUALITY = 30;
export const DEFAULT_BLUR_UP_AMOUNT = 50;

export const DEFAULT_FALLBACK_IMAGE_QUALITY = 75;

const DEFAULT_BLUR_IMAGE_BUILDER = (imageUrlBuilder, options) => {
  return imageUrlBuilder
    .width(options.width || DEFAULT_BLUR_UP_IMAGE_WIDTH)
    .quality(options.quality || DEFAULT_BLUR_UP_IMAGE_QUALITY)
    .blur(options.blurAmount || DEFAULT_BLUR_UP_AMOUNT)
    .fit("clip");
};

const DEFAULT_IMAGE_BUILDER = (imageUrlBuilder, options) => {
  const result = imageUrlBuilder.quality(options.quality || DEFAULT_FALLBACK_IMAGE_QUALITY).fit("clip");

  if (options.width !== null) {
    return result.width(options.width);
  }

  return result;
};

function getSanityRefId(image) {
  if (typeof image === "string") {
    return image;
  }

  if (image.asset) {
    return image.asset._ref || image.asset._id;
  }

  return image._ref || image._id || "";
}

function getSrc(image, src) {
  if (!src || !image.originalFilename) return src;

  const [imgUrl, imgParams] = src.split("?");
  return `${imgUrl}/${image.originalFilename}?${imgParams}`;
}

function getImageDimensions(id) {
  const dimensions = id.split("-")[2];

  const [width, height] = dimensions.split("x").map((num) => parseInt(num, 10));
  const aspectRatio = width / height;

  return { width, height, aspectRatio };
}

function getCroppedDimensions(image, baseDimensions) {
  const crop = image.crop;

  if (!crop) {
    return baseDimensions;
  }

  const { width, height } = baseDimensions;
  const croppedWidth = width * (1 - (crop.left + crop.right));
  const croppedHeight = height * (1 - (crop.top + crop.bottom));

  return {
    width: croppedWidth,
    height: croppedHeight,
    aspectRatio: croppedWidth / croppedHeight,
  };
}

export default function useNextSanityImage(sanityClient, image, options = {}) {
  const enableBlurUp = options.enableBlurUp === undefined ? true : options.enableBlurUp;

  const blurAmount = options.blurUpAmount || null;
  const blurUpImageQuality = options.blurUpImageQuality || null;
  const blurUpImageWidth = options.blurUpImageWidth || null;

  const blurUpImageBuilder = options.blurUpImageBuilder || DEFAULT_BLUR_IMAGE_BUILDER;
  const imageBuilder = options.imageBuilder || DEFAULT_IMAGE_BUILDER;

  return useMemo(() => {
    if (!image) {
      return null;
    }

    // If the image has an alt text but does not contain an actual asset, the id will be
    // undefined: https://github.com/bundlesandbatches/next-sanity-image/issues/14
    const id = image ? getSanityRefId(image) : null;
    if (!id) {
      return null;
    }

    const originalImageDimensions = getImageDimensions(id);
    const croppedImageDimensions = getCroppedDimensions(image, originalImageDimensions);

    const loader = ({ width, quality }) => {
      return getSrc(
        image,
        imageBuilder(imageUrlBuilder(sanityClient).image(image).auto("format"), {
          width,
          originalImageDimensions,
          croppedImageDimensions,
          quality: quality || null,
        }).url() || ""
      );
    };

    const baseImgBuilderInstance = imageBuilder(imageUrlBuilder(sanityClient).image(image).auto("format"), {
      width: null,
      originalImageDimensions,
      croppedImageDimensions,
      quality: null,
    });

    const width =
      baseImgBuilderInstance.options.width ||
      (baseImgBuilderInstance.options.maxWidth
        ? Math.min(baseImgBuilderInstance.options.maxWidth, croppedImageDimensions.width)
        : croppedImageDimensions.width);

    const height =
      baseImgBuilderInstance.options.height ||
      (baseImgBuilderInstance.options.maxHeight
        ? Math.min(baseImgBuilderInstance.options.maxHeight, croppedImageDimensions.height)
        : Math.round(width / croppedImageDimensions.aspectRatio));

    const props = {
      loader,
      src: getSrc(image, baseImgBuilderInstance.url()),
      width,
      height,
      alt: image.alt,
      title: image.title,
    };

    if (enableBlurUp) {
      const blurImgBuilderInstance = blurUpImageBuilder(imageUrlBuilder(sanityClient).image(image).auto("format"), {
        width: blurUpImageWidth,
        originalImageDimensions,
        croppedImageDimensions,
        quality: blurUpImageQuality,
        blurAmount: blurAmount,
      });

      return {
        ...props,
        blurDataURL: getSrc(image, blurImgBuilderInstance.url()),
        placeholder: "blur",
      };
    }

    return { ...props, placeholder: "empty" };
  }, [
    blurAmount,
    blurUpImageBuilder,
    blurUpImageQuality,
    blurUpImageWidth,
    enableBlurUp,
    imageBuilder,
    image,
    sanityClient,
  ]);
}
