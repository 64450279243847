import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";

import useUser from "hooks/useUser";
import { getClientCookie } from "services/auth/api";
import useGeolocationInfo from "hooks/useGeolocationInfo";

// This component can add further checks for specific protected routes
const AuthCheck = ({ children, pageTitle }) => {
  const { updateLoggedIn, isLoggedIn, user } = useUser();
  const router = useRouter();
  const { geoLocationInfo, isLoading: geolocationInfoLoading } = useGeolocationInfo();

  const [prevPath, setPrevPath] = useState();

  useEffect(() => {
    if (geolocationInfoLoading) return;
    if (router.asPath !== prevPath) {
      setPrevPath(router.asPath);
      const finalProps = { path: router.asPath };
      if (user) {
        finalProps.userTraits = {
          email: user.email,
          name: (user.firstName || "") + (user.lastName ? ` ${user.lastName}` : ""),
          phone: user.phone,
          address: {
            street: user.places?.addressLine1,
            city: user.places?.city,
            state: user.places?.state,
            postalCode: user.places?.zipCode,
          },
        };
      }
      window.analytics.page(undefined, pageTitle, finalProps, {
        context: {
          location: geoLocationInfo?.location,
          ip: geoLocationInfo?.ip,
        },
      });
    }
  }, [router?.asPath, geolocationInfoLoading, geoLocationInfo]);

  useEffect(() => {
    const clientCookie = getClientCookie();
    if (!isEmpty(clientCookie)) {
      updateLoggedIn(clientCookie);
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    if ((router.asPath || "").includes("onboarding")) {
      router.push("/dashboard");
    }
  }, [router.asPath, isLoggedIn]);

  return children;
};

export default AuthCheck;
