import React, { useState, useContext } from "react";

const AuthContext = React.createContext();

const { Provider } = AuthContext;

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    currentUser: null,
    isImpersonateMode: false,
    isUnverified: false,
  });

  const updateAuthStatus = (newState) => {
    setAuthState((prev) => ({
      ...prev,
      ...newState,
    }));
  };

  return (
    <Provider
      value={{
        authState,
        updateAuthStatus,
      }}
    >
      {children}
    </Provider>
  );
};

export const useAuthState = () => {
  const authState = useContext(AuthContext);

  if (typeof authState === "undefined") {
    throw new Error("useAuthState must be used within AuthProvider");
  }

  return authState;
};
