import { createContext, useReducer } from "react";

const initialState = {
  toasts: [],
};

const ToastContext = createContext({
  state: initialState,
  dispatch: () => {},
});

const toastReducer = (state, action) => {
  switch (action.type) {
    case "toast/addToast":
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case "toast/removeToast":
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload),
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const ToastProvider = ({ children }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  return <ToastContext.Provider value={{ state, dispatch }}>{children}</ToastContext.Provider>;
};

export { initialState, ToastContext, toastReducer };

export default ToastProvider;
