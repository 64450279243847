import { useQuery } from "react-query";
import { getUserGeolocationInfo } from "services/geolocation/api";

const useGeolocationInfo = () => {
  const { data, isLoading } = useQuery("geolocation_info", getUserGeolocationInfo, {
    refetchOnWindowFocus: false,
  });
  return {
    geoLocationInfo: data,
    isLoading,
  };
};

export default useGeolocationInfo;
